<template>
    <v-dialog persistent v-model="showAssignLicenceUserDialog" width="500">
      <v-card :loading="loadingAssignLicence">
        <v-form
          lazy-validation
          v-model="form"
          ref="assignLicenceForm"
          :disable="loading"
        >
          <v-card-title>
            <span class="text-h6">Assign Licence</span>
            <v-spacer></v-spacer>
            <v-btn @click="closeCustomLicenceDialog" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="selectLicenceKey"
                  :rules="[rules.required]"
                  :items="licenceKeys"
                  label="Select Licence"
                  persistent-hint
                  return-object
                  item-text="licence_name"
                  single-line
                  outlined
                  required
                  item-value="id"
                >
                </v-combobox>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model.number="quantity"
                  :rules="[rules.required, rules.mustMoreThanZero]"
                  color="deep-purple"
                  label="Quantity"
                  type="number"
                  outlined
                  class="inputPrice"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="selectedDateType"
                  :items="dateTypes"
                  :rules="[rules.required]"
                  label="Date Type"
                  outlined
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
                <div v-if="selectedDateType === 'custom'">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="getEndDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="getEndDate"
                        label="Custom Date"
                        hint="MM/DD/YYYY format"
                        outlined
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :allowed-dates="disablePastDates"
                      v-model="getEndDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(getEndDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
              </v-col>
              <v-col cols="6">
                <v-col v-if="selectedDateType === 'custom'" cols="12">
                  <v-text-field
                    v-model.number="duration"
                    :rules="[rules.required, rules.mustMoreThanZero]"
                    auto-select-first
                    label="*Duration"
                    color="deep-purple"
                    type="number"
                    outlined
                    class="inputPrice"
                  ></v-text-field>
                </v-col>
              </v-col>
            </v-row>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="createNewLicence()"
                :disabled="!form || loading"
                color="primary"
              >
                ASSIGN
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
</template>

<script>
import converter from '@/mixins/converter'
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'

export default converter.extend({
  props: {
    showAssignLicenceUserDialog: { type: Boolean, default: false },
    licenceKeys: { type: Array, default: () => [] },
    selectedUser: { type: Object, required: true }
  },
  watch: {
    getEndDate: {
      handler (n) {
        this.watchGetEndDate(n)
      },
      deep: true
    }
  },
  data: () => ({
    editDialog: false,
    loading: false,
    form: true,
    duration: 0,
    quantity: 1,
    showCustomLicenceDialog: false,
    loadingAssignLicence: false,
    newLicencePayload: {},
    selectLicenceKey: null,
    getEndDate: null,
    menu: false,
    featuresPayload: [],
    features: [],
    addressPayload: {},
    rules: {
      length: (len) => (v) =>
        (v || '').length <= len || `Name length can max ${len}.`,
      required: (v) => !!v || 'This field is required',
      numberRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true
        return 'Number has to be between 0 and 9'
      },
      mustMoreThanZero: (v) => {
        if (!isNaN(v) && v > 0) return true
        return 'Number must be more than 0'
      }
    },
    select: ['Vuetify'],
    items: ['Programming', 'Design', 'Vue', 'Vuetify'],
    selectedDateType: '',
    dateTypes: ['monthly', 'yearly', 'custom']
  }),

  methods: {
    async createNewLicence () {
      if (this.$refs.assignLicenceForm.validate()) {
        this.loadingAssignLicence = true
        this.form = false
        const assignLicencePayload = {
          licence_id: this.selectLicenceKey.id,
          user_id: this.selectedUser.id,
          date_type: this.selectedDateType,
          quantity: this.quantity,
          duration: this.duration
        }
        await apiService
          .post('admin-assign-licence-to-user', assignLicencePayload)
          .then(() => {
            Swal.fire(
              'Assigned!',
              'Licence is assigned to user successfully!',
              'success'
            )
            this.loadingAssignLicence = false
            this.form = true
            this.closeCustomLicenceDialog()
            this.$emit('callSyncUserLicences')
          })
          .catch((err) => {
            console.log('ERRO', err)
            Swal.fire('Error!', `${err.response}`, 'success')
          })
      }
    },

    watchGetEndDate (date) {
      const convertedDate = new Date(date)
      const durationDay = this.calculateExpireTime(convertedDate.getTime())
      this.duration = durationDay
    },

    disablePastDates (val) {
      return val >= new Date().toISOString().substr(0, 10)
    },

    closeCustomLicenceDialog () {
      this.$emit('closeShowAssignLicenceDialog')
      this.$refs.assignLicenceForm.reset()
    },

    openCustomerLicenceDialog () {
      this.showCustomLicenceDialog = true
    }
  }
})
</script>

    <style>
.inputPrice input[type='number'] {
  -moz-appearance: textfield;
}

.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
